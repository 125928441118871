import { Checkbox, Grid, Stack } from "@mui/material";
import { CustomButton } from "components/CustomButton";
import { CustomSwitch } from "components/CustomSwitch";
import { TextField } from "components/Fields";
import { useAlert } from "hooks/useAlert";
import { useFormHook } from "hooks/useFormHook";
import React, { useState } from "react";
import { getRegisterMencionServices } from "services/upload";
import { TPremios1 } from "types/files";
import { RULES } from "../../constants";
import FileZone from "../Fields/FileZone";
import style from "./FormMenciones.module.scss";
import { LightTooltip } from "components/ToolTip/ToolTip";
import { DialogConfirm } from "components/DialogConfirm";
import { TOpenModals } from "types";
import { DIALOG } from "../../constants";
import TextTerminos from "components/TextTerminos";

const errorDefault = {
  cedula: true,
  carta: true,
  cartaA: true,
  tabla: true,
  fotos: true,
};

const initialDatarow = {
  nombrePropuesta: "",
  descripcion: "",
};

const Mencion9: React.FC<{
  mencionId: string;
  getPostulaciones: any;
  postulaciones: any;
}> = ({ mencionId, getPostulaciones, postulaciones }) => {
  const [cedula, setCedula] = useState<any>([]);
  const [carta, setCarta] = useState<any>([]);
  const [cartaA, setCartaA] = useState<any>([]);
  const [tabla, setTabla] = useState<any>([]);
  const [presentacion, setPresentacion] = useState<any>([]);
  const [fotos, setFotos] = useState<any>([]);
  const [video, setVideo] = useState<any>([]);
  const [groups, setGroups] = useState<any>(false);
  const [error, setError] = useState<Partial<TPremios1>>({});
  const [errorsFiles, setErrorsFiles] = useState<any>([]);
  const [loadingService, setLoadingService] = useState(false);
  const { openAlert } = useAlert();
  const { control, handleSubmit, errors, reset } = useFormHook(initialDatarow);
  const [openModal, setOpenModal] = React.useState<TOpenModals>({
    dialogSubmit: false,
    dialogTerms: false,
  });
  const [statusTerms, setStatusTerms] = useState<boolean>(false);
  const onSubmit = async (datos: any) => {
    const data = new FormData();

    try {
      setLoadingService(true);
      if (
        !cedula.length ||
        !fotos.length ||
        !cartaA.length ||
        errorsFiles.length
      ) {
        throw new Error("");
      }
      if (fotos.length != 5) {
        return openAlert("Se deben cargar 5 fotos", {
          variant: "error",
        });
      }

      if (groups) {
        if (!tabla.length || !carta.length) {
          throw new Error("");
        }
      }

      data.append("cedula", cedula[0]?.file);
      data.append("cartaPostulacion", carta[0]?.file);
      data.append("cartaAutorizacion", cartaA[0]?.file);
      data.append("documentExel", tabla[0]?.file);
      data.append("PresentacionDescriptiva", presentacion[0]?.file);
      for (const file in fotos) {
        data.append("fotos", fotos[file].file);
      }

      data.append("videoDemostrativo", video[0]?.file);
      data.append("nombrePropuesta", datos.nombrePropuesta);
      data.append("descripcion", datos.descripcion);
      data.append("premioId", "3");
      data.append("mencionId", mencionId);
      data.append("grupal", groups);
      if (!groups) {
        data.delete("documentExel");
        data.delete("cartaPostulacion");
      }

      await getRegisterMencionServices({ data, authRequire: true });

      setCedula([]);
      setCarta([]);
      setCartaA([]);
      setTabla([]);
      setPresentacion([]);
      setVideo([]);
      setFotos([]);
      reset(initialDatarow);

      openAlert(
        `Se registró exitosamente a la mención ${
          mencionId === "9" ? "Autodidacta" : "Profesional"
        }`
      );
    } catch (error: any) {
      setError(errorDefault);
      openAlert(
        error.response
          ? error?.response?.data?.msj
          : "llenar los campos requeridos",
        { variant: "error" }
      );
    } finally {
      setLoadingService(false);
      getPostulaciones();
    }
  };

  const onDialog = (e: any) => {
    e.preventDefault();
    setOpenModal({ dialogSubmit: true });
  };

  const onDialogTerms = (e: any) => {
    e.preventDefault();
    setOpenModal({ dialogTerms: true });
  };

  function termsAccept() {
    setStatusTerms(true);
  }

  return (
    <section>
      <form onSubmit={postulaciones.length ? onDialog : handleSubmit(onSubmit)}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            marginBottom: "23px",
          }}
        >
          <div>
            <h4>Tipo de postulación</h4>
            <CustomSwitch groups={groups} setGroups={setGroups} />
          </div>
        </div>
        <Stack spacing={2}>
          <Grid
            container
            spacing={4}
            sx={{ position: "relative", fontFamily: "ronniaExtraBold" }}
          >
            <Grid item xs={12} sm={4}>
              <h4 className={style["title"]}>*Título de la propuesta</h4>
              <TextField
                name="nombrePropuesta"
                label="Título de la propuesta"
                control={control}
                variant="standard"
                rules={RULES.titulo}
                error={Boolean(errors.nombrePropuesta)}
                errmsg={errors.nombrePropuesta}
                multiline
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <h4 className={style["title"]}>*Descripción de la propuesta</h4>
              <TextField
                name="descripcion"
                label="Descripción de la propuesta"
                control={control}
                variant="standard"
                rules={RULES.resumen}
                error={Boolean(errors.descripcion)}
                errmsg={errors.descripcion}
                multiline
              />
            </Grid>
            <LightTooltip title="Haga click para cargar la copia de cédula de identidad en formato PDF">
              <Grid item xs={12} sm={4} alignSelf={"flex-end"}>
                <h4 className={style["title"]}>
                  *Copia de cédula de identidad
                </h4>
                <FileZone
                  files={cedula}
                  setFiles={setCedula}
                  typeFiles="pdf"
                  label={"Copia de cédula de identidad"}
                  labelError={"Campo Requerido"}
                  error={error}
                  name="cedula"
                  setError={setError}
                  errorsFiles={errorsFiles}
                />
              </Grid>
            </LightTooltip>
            {groups && (
              <LightTooltip title="Haga click para cargar la Tabla en la que se solicitan datos del resto de las personas miembros del grupo en formato PDF o XLSX">
                <Grid item xs={12} sm={4} alignSelf={"flex-end"}>
                  <h4 className={style["title"]}>
                    *Tabla con los datos del resto de las personas miembros del
                    grupo
                  </h4>
                  <FileZone
                    files={tabla}
                    setFiles={setTabla}
                    typeFiles="pdf/xlsx"
                    label={"Tabla de las personas miembros del grupo"}
                    labelError={"Campo Requerido"}
                    error={error}
                    name="tabla"
                    setError={setError}
                    errorsFiles={errorsFiles}
                  />
                </Grid>
              </LightTooltip>
            )}
            {groups && (
              <LightTooltip title="Haga click para cargar la carta de postulación en formato PDF">
                <Grid item xs={12} sm={4} alignSelf={"flex-end"}>
                  <h4 className={style["title"]}>*Carta de postulación</h4>
                  <FileZone
                    files={carta}
                    setFiles={setCarta}
                    typeFiles="pdf"
                    label={"Carta de postulación"}
                    labelError={"Campo Requerido"}
                    error={error}
                    name="carta"
                    setError={setError}
                    errorsFiles={errorsFiles}
                  />
                </Grid>
              </LightTooltip>
            )}
            <LightTooltip
              title="Haga click para cargar la carta de autorización para la exhibición de la
                  invención, asi como para el uso de la información proporcionada
                  al MINCYT mediante la postulación (firmada por la persona o las
                  personas postulantes) en formato PDF."
            >
              <Grid item xs={12} sm={4} alignSelf={"flex-end"}>
                <h4 className={style["title"]}>
                  *Carta de autorización para la exhibición de la invención
                </h4>
                <FileZone
                  files={cartaA}
                  setFiles={setCartaA}
                  typeFiles="pdf"
                  label={"Carta de autorización"}
                  labelError={"Campo Requerido"}
                  error={error}
                  name="carta"
                  setError={setError}
                  errorsFiles={errorsFiles}
                />
              </Grid>
            </LightTooltip>
            <LightTooltip title="Haga click para cargar las 5 fotos de la invención en formato PNG o JPG">
              <Grid item xs={12} sm={4} alignSelf={"flex-end"}>
                <h4 className={style["title"]}>*Fotos de la invención</h4>
                <FileZone
                  files={fotos}
                  setFiles={setFotos}
                  typeFiles="image"
                  label={"5 Fotos"}
                  labelError={"Campo Requerido"}
                  error={error}
                  name="fotos"
                  setError={setError}
                  maxFiles={5}
                  allowMultiple={true}
                  errorsFiles={errorsFiles}
                />
              </Grid>
            </LightTooltip>
            <LightTooltip
              title="Haga click para cargar la presentación descriptiva de la propuesta de no más de
                  10 láminas en formato PDF (3mb max)"
            >
              <Grid item xs={12} sm={4} alignSelf={"flex-end"}>
                <h4 className={style["title"]}>
                  Presentación (no obligatoria)
                </h4>
                <FileZone
                  files={presentacion}
                  setFiles={setPresentacion}
                  typeFiles="pdf"
                  maxFileSize="3MB"
                  label={"Presentación"}
                  errorsFiles={errorsFiles}
                />
              </Grid>
            </LightTooltip>
            <LightTooltip title="Haga click para cargar el video demostrativo. Solo se permite un tamaño máximo de 30MB y en formato MP4">
              <Grid item xs={12} sm={4} alignSelf={"flex-end"}>
                <h4 className={style["title"]}>
                  Video demostrativo (no obligatorio)
                </h4>

                <FileZone
                  files={video}
                  setFiles={setVideo}
                  maxFileSize="30MB"
                  typeFiles="mp4"
                  label={"Video demostrativo"}
                  errorsFiles={errorsFiles}
                />
              </Grid>
            </LightTooltip>
          </Grid>
          <Grid item xs={12} display={"flex"}>
            <Checkbox
              checked={statusTerms}
              onChange={(status: any) => {
                setStatusTerms(status.target.checked);
              }}
            />
            <p>
              He leído y acepto los Términos y Condiciones{" "}
              <span
                onClick={onDialogTerms}
                style={{
                  color: "#0a307e",
                  cursor: "pointer",
                  textDecorationLine: "underline",
                }}
                className="paragraph"
              >
                click para leerlos
              </span>
            </p>
          </Grid>

          <Stack alignItems="flex-end">
            <CustomButton
              label="Postular"
              typeVariant="outlined"
              typeAction="submit"
              disabled={statusTerms ? false : true}
              disabledReload={statusTerms ? false : true}
            />
          </Stack>
        </Stack>
      </form>

      <DialogConfirm
        onSubmit={termsAccept}
        title={<span className="titles">Terminos y Condiciones</span>}
        openModal={openModal.dialogTerms}
        setOpenModal={setOpenModal}
      >
        <TextTerminos />
      </DialogConfirm>

      <DialogConfirm
        onSubmit={handleSubmit(onSubmit)}
        title={DIALOG.titulo}
        openModal={openModal.dialogSubmit}
        setOpenModal={setOpenModal}
      >
        {DIALOG.descripcion}
      </DialogConfirm>
    </section>
  );
};

export default Mencion9;
