import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

import styles from './DialogConfirm.module.scss';

const DialogConfirm = ({
  title,
  children,
  setOpenModal,
  openModal,
  onSubmit,
  eventAccept,
}: TDialogConfirmProps) => {
  const handleClose = () => {
    setOpenModal({ dialogSubmit: false });
  };


  return (
    <Dialog
      className={styles['dialog-container']}
      open={openModal}
      onClose={handleClose}>
      <DialogTitle className={styles['dialog-confirm__title']}>
        {title}
      </DialogTitle>
      <DialogContent className={styles['dialog-confirm__children']}>
        <p>{children}</p>
      </DialogContent>
      <DialogActions className={styles['dialog-confirm__actions']}>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button
          onClick={(e) => {
            onSubmit(e);
            handleClose();
          }}>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export type TDialogConfirmProps = {
  openModal: boolean;
  title: any;
  children: React.ReactNode;
  setOpenModal: any;
  onSubmit: any;
  eventAccept?: any;
};

export default React.memo(DialogConfirm);
