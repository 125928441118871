
import { request } from 'lib/request';





export function getMencionServices({  authRequire }: any) {
  return request('/postulaciones', {
    method: 'get',
    authRequire,
  });
}

