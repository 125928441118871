import { Checkbox, Grid, Stack, Tooltip } from "@mui/material";
import { CustomButton } from "components/CustomButton";
import { CustomSwitch } from "components/CustomSwitch";
import { TextField } from "components/Fields";
import { RULES } from "constants/index";
import { useAlert } from "hooks/useAlert";
import { useFormHook } from "hooks/useFormHook";
import { useState } from "react";
import { getRegisterMencionServices } from "services/upload";
import { TPremios1 } from "types/files";
import FileZone from "../Fields/FileZone";
import style from "./FormMenciones.module.scss";
import { TOpenModals } from "types";
import { DialogConfirm } from "components/DialogConfirm";
import { DIALOG } from "constants/dialog";
import { LightTooltip } from "components/ToolTip/ToolTip";
import TextTerminos from "components/TextTerminos";

const errorDefault = {
  cedula: true,
  documentExel: false,
  cartaPostulacion: false,
};

const initialDatarow = {
  descripcion: "",
};

const Mencion11 = ({ getPostulaciones, postulaciones }: any) => {
  const [cedula, setCedula] = useState<any>([]);
  const [documentExel, setDocumentExel] = useState<any>([]);
  const [cartaPostulacion, setCartaPostulacion] = useState<any>([]);
  const [presentacion, setPresentacion] = useState<any>([]);
  const [groups, setGroups] = useState<any>(false);
  const [error, setError] = useState<Partial<TPremios1>>({});
  const [errorsFiles, setErrorsFiles] = useState<any>([]);
  const [loadingService, setLoadingService] = useState(false);
  const { openAlert } = useAlert();
  const { control, handleSubmit, errors, reset } = useFormHook(initialDatarow);
  const [openModal, setOpenModal] = useState<TOpenModals>({
    dialogSubmit: false,
    dialogTerms: false,
  });
  const [statusTerms, setStatusTerms] = useState<boolean>(false);

  const onSubmit = async (datos: any) => {
    const data = new FormData();

    try {
      setLoadingService(true);
      if (!cedula.length
            // || !presentacion.length
            || errorsFiles.length) {
        throw new Error("cedula");
      }

      if (groups) {
        if (!cartaPostulacion.length || !documentExel.length) {
          throw new Error("");
        }
      }

      data.append("cedula", cedula[0]?.file);
      data.append("documentExel", documentExel[0]?.file);
      data.append("cartaPostulacion", cartaPostulacion[0]?.file);
      data.append("descripcion", datos.descripcion);
      data.append("presentacion", presentacion[0]?.file);
      data.append("premioId", "4");
      data.append("mencionId", "11");
      data.append("grupal", groups);
      if (!groups) {
        data.delete("documentExel");
        data.delete("cartaPostulacion");
      }

      await getRegisterMencionServices({ data, authRequire: true });
      setCedula([]);
      setDocumentExel([]);
      setCartaPostulacion([]);
      setPresentacion([]);
      reset(initialDatarow);

      openAlert(
        "Se registró exitosamente a la mención Premio Especial a la Innovación del Trabajador o Trabajadora"
      );
    } catch (error: any) {
      setError(errorDefault);
      openAlert(
        error.response
          ? error?.response?.data?.msj
          : "llenar los campos requeridos",
        { variant: "error" }
      );
    } finally {
      setLoadingService(false);
      getPostulaciones();
    }
  };

  const onDialog = (e: any) => {
    e.preventDefault();
    setOpenModal({ dialogSubmit: true });
  };

  const onDialogTerms = (e: any) => {
    e.preventDefault();
    setOpenModal({ dialogTerms: true });
  };

  function termsAccept() {
    setStatusTerms(true);
  }

  return (
    <section>
      <form onSubmit={postulaciones.length ? onDialog : handleSubmit(onSubmit)}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            marginBottom: "23px",
          }}
        >
          <div>
            <h4>Tipo de postulación</h4>
            <CustomSwitch groups={groups} setGroups={setGroups} />
          </div>
        </div>
        <Stack spacing={2}>
          <Grid
            container
            spacing={4}
            sx={{
              position: "relative",
              marginTop: "15px",
              fontFamily: "ronniaExtraBold",
            }}
          >
            <LightTooltip title="Cargar la copia de cédula de identidad en formato PDF">
              <Grid item xs={12} sm={6} alignSelf={"flex-end"}>
                <h4 className={style["title"]}>
                  *Copia de cédula de identidad
                </h4>
                <FileZone
                  files={cedula}
                  setFiles={setCedula}
                  typeFiles="pdf"
                  label={"Copia de cédula de identidad"}
                  labelError={"Campo Requerido"}
                  error={error}
                  name="cedula"
                  setError={setError}
                  errorsFiles={errorsFiles}
                />
              </Grid>
            </LightTooltip>
            {groups && (
              <LightTooltip title="Cargar la tabla en la que se solicitan datos del resto de las personas miembros del grupo en formato PDF o xlsx">
                <Grid item xs={12} sm={6}>
                  <h4 className={style["title"]}>
                    *Tabla con los datos del resto de las personas miembros del
                    grupo
                  </h4>
                  <FileZone
                    files={documentExel}
                    setFiles={setDocumentExel}
                    typeFiles="pdf/xlsx"
                    label={"Tabla de los miembros del grupo"}
                    labelError={"Campo Requerido"}
                    rules={RULES.no_required_no_special}
                    error={error}
                    name="documentExel"
                    setError={setError}
                    errorsFiles={errorsFiles}
                  />
                </Grid>
              </LightTooltip>
            )}
            {groups && (
              <LightTooltip title="Cargar la carta de postulación en formato PDF">
                <Grid item xs={12} sm={6} alignSelf={"flex-end"}>
                  <h4 className={style["title"]}>*Carta de postulación</h4>
                  <FileZone
                    files={cartaPostulacion}
                    setFiles={setCartaPostulacion}
                    typeFiles="pdf"
                    label={"Carta de postulación"}
                    labelError={"Campo Requerido"}
                    rules={RULES.no_required_no_special}
                    error={error}
                    name="cartaPostulacion"
                    setError={setError}
                    errorsFiles={errorsFiles}
                  />
                </Grid>
              </LightTooltip>
            )}

            <LightTooltip
              title="Cargar la Presentación sobre aportes en formato PDF. Presentación sobre aportes de personas o grupos responsables de
                  emprender acciones o mantener procesos de aplicación del
                  conocimiento con resultados relevantes. (3mb max)"
            >
              <Grid item xs={12} sm={6} alignSelf={"flex-end"}>
                <h4 className={style["title"]}>
                  Presentación sobre aportes (no obligatorio)
                </h4>
                <FileZone
                  files={presentacion}
                  setFiles={setPresentacion}
                  typeFiles="pdf"
                  maxFileSize="3MB"
                  label={"Presentación"}
                  name="presentacion"
                  errorsFiles={errorsFiles}
                />
              </Grid>
            </LightTooltip>

            <Grid item xs={12} sm={12} alignSelf={"center"}>
              <h4 className={style["title"]}>
                *Descripción de la acción o proceso de aplicación del
                conocimiento (obligatorio)
              </h4>
              <TextField
                name="descripcion"
                label=""
                control={control}
                variant="standard"
                rules={RULES.resumen}
                error={Boolean(errors.descripcion)}
                errmsg={errors.descripcion}
                multiline
              />
            </Grid>
          </Grid>
          <Grid item xs={12} display={"flex"}>
            <Checkbox
              checked={statusTerms}
              onChange={(status: any) => {
                setStatusTerms(status.target.checked);
              }}
            />
            <p>
              He leído y acepto los Términos y Condiciones{" "}
              <span
                onClick={onDialogTerms}
                style={{
                  color: "#0a307e",
                  cursor: "pointer",
                  textDecorationLine: "underline",
                }}
                className="paragraph"
              >
                click para leerlos
              </span>
            </p>
          </Grid>

          <Stack alignItems="flex-end">
            <CustomButton
              label="Postular"
              typeVariant="outlined"
              typeAction="submit"
              disabled={statusTerms ? false : true}
              disabledReload={statusTerms ? false : true}
            />
          </Stack>
        </Stack>
      </form>

      <DialogConfirm
        onSubmit={termsAccept}
        title={<span className="titles">Terminos y Condiciones</span>}
        openModal={openModal.dialogTerms}
        setOpenModal={setOpenModal}
      >
        <TextTerminos />
      </DialogConfirm>

      <DialogConfirm
        onSubmit={handleSubmit(onSubmit)}
        title={DIALOG.titulo}
        openModal={openModal.dialogSubmit}
        setOpenModal={setOpenModal}
      >
        {DIALOG.descripcion}
      </DialogConfirm>
    </section>
  );
};

export default Mencion11;
