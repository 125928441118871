import { removeItemLocal, searchItemLocal } from "utils/helpers";
import jwt_decode from "jwt-decode";
import { localToken } from "constants/index";

export const verificarToken = () => {
  const token = searchItemLocal(localToken);
  if (token) {
      if (!token) {
      removeItemLocal(localToken);
    }

    const decoded: any = jwt_decode(token);

    if (decoded.exp < Math.floor(Date.now() / 1000)) {
      removeItemLocal(localToken);
    }
  }
};
