export const handleChangeTab = (
  _: React.SyntheticEvent,
  newValue: number,
  setValue: any
) => {
  setValue(newValue);
};

export const saveData = async (
  data: unknown,
  {
    groups,
    value,
    setValue,
    setQuestionsValidate,
    setIsDirty,
    handleSubmit
  }: any
) => {
  const newValue = value === groups.length - 1 ? 0 : value + 1;
  setIsDirty(false);

  await handleSubmit();

  if (value == 1) {
    setValue(newValue);
  }

  if (value == 2) {
    setValue(newValue);
  }

  if (newValue === 0) {
    setQuestionsValidate([newValue]);
  } else {
    setQuestionsValidate((prev: any) => [...prev, newValue]);
  }
};

export function a11yProps(index: string) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
