import React, { useEffect, useMemo, useState } from "react";
import styles from "./dashboard.module.scss";

import { CardNormal, TabPremio1 } from "components/index";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TabPremio2, TabPremio4, TabPremio3 } from "components/Tabs";
import { getMencionServices } from "services/postulaciones";
import PostulacionLista from "components/PostulacionLista/PostulacionLista";
import { GridColDef } from "@mui/x-data-grid";
import { TPageState } from "types";
import image from "../../img/Recurso_19.png";
import { getPreEmitDiagnostics } from "typescript";

const Dashboard = () => {
  const [pageState, setPageState] = React.useState<TPageState>({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 5,
  });
  const [postulaciones, setPostulaciones] = React.useState([]);

  const premio = [
    {
      id: 1,
      name: "Novel Investigador o Novel investigadora",
    },
    {
      id: 2,
      name: "Amplia trayectoria",
    },
    {
      id: 3,
      name: "Grupo de Investigación Consolidado",
    },
    {
      id: 4,
      name: "Ciencias Naturales",
    },
    {
      id: 5,
      name: "Ciencias de la Salud",
    },
    {
      id: 6,
      name: "Ciencias Exactas",
    },
    {
      id: 7,
      name: "Ciencias Sociales y Humanas",
    },
    {
      id: 8,
      name: "Investigación Tecnológica",
    },
    {
      id: 9,
      name: "Autodidacta",
    },
    {
      id: 10,
      name: "Profesional",
    },
    {
      id: 11,
      name: "Premio Especial a la Innovación del Trabajador o Trabajadora",
    },
    {
      id: 12,
      name: "Premio Especial a la Mujer y Niña de la Ciencia",
    },
    {
      id: 13,
      name: "Premio Especial a la Juventud",
    },
  ];

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "mencionId",
        headerName: "Menciones postuladas",
        width: 500,
        renderCell: (params) => {
          return premio[params?.value - 1]?.name;
        },
      },
      {
        field: "postulaciones",
        headerName: "Cantidad de postulaciones realizadas",
        width: 300,
      },
    ],
    []
  );

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const getPostulaciones = async () => {
    const res = await getMencionServices({ authRequire: true });
    setPostulaciones(res.data);
    const count_mencion_ids = (array: any) => {
      // Create an empty dictionary to store the mencion ids and their counts.
      const mencion_ids = {} as any;

      // Iterate over the array of objects.
      for (const obj of array) {
        // Get the mencion id from the object.
        const mencion_id: any = obj["mencionId"];

        // If the mencion id is not in the dictionary, add it with a count of 0.
        if (!mencion_ids.hasOwnProperty(mencion_id)) {
          mencion_ids[mencion_id] = 0;
        }

        // Increment the count of the mencion id by 1.
        mencion_ids[mencion_id] += 1;
      }

      // Return the dictionary of mencion ids and their counts.

      const result = [];
      for (const key in mencion_ids) {
        const value = mencion_ids[key];
        result.push({
          id: key,
          mencionId: key,
          postulaciones: value,
        });
      }

      // Return the array of mencion ids and postulaciones.
      return result;
    };
    count_mencion_ids(res.data);

    setPageState((prev: TPageState) => ({
      ...prev,
      isLoading: false,
      data: count_mencion_ids(res.data),
    }));
  };

  useEffect(() => {
    getPostulaciones();
  }, []);

  return (
    <section>
      <CardNormal>
        <Container maxWidth="lg">
          <Accordion
            expanded={expanded === "panel0"}
            onChange={handleChange("panel0")}
            style={{ zIndex: "999" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel0a-content"
              id="panel0a-header"
            >
              <figure className={styles["imageDisabled"]}>
                <img style={{ maxWidth: "85px" }} src={image} alt="" className={styles["registerImage"]} />
              </figure>
              <h2 className={styles["titulo"]}>Postulaciones Registradas</h2>
            </AccordionSummary>
            <AccordionDetails>
              <PostulacionLista
                columns={columns}
                pageState={pageState}
                setPageState={setPageState}
                premios={premio}
              />
            </AccordionDetails>
          </Accordion>
          <br />
          <br />
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            style={{ zIndex: "999" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h2 className={styles["titulo"]}>
                Premio Nacional de Ciencia y Tecnología
              </h2>
            </AccordionSummary>
            <AccordionDetails>
              <section className={styles["premio-container"]}>
                <TabPremio1
                  premio={premio.slice(0, 3)}
                  getPostulaciones={getPostulaciones}
                  postulaciones={postulaciones}
                />
              </section>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            style={{ zIndex: "999" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h2 className={styles["titulo"]}>
                Premio Nacional al Mejor Trabajo Científico, Tecnológico y de
                Innovación
              </h2>
            </AccordionSummary>
            <AccordionDetails>
              <section className={styles["premio-container"]}>
                <TabPremio1
                  premio={premio.slice(3, 8)}
                  getPostulaciones={getPostulaciones}
                  postulaciones={postulaciones}
                />
              </section>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            style={{ zIndex: "999" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <h2 className={styles["titulo"]}>
                Premio Nacional a la Inventiva Tecnológica Popular "Luis
                Zambrano"
              </h2>
            </AccordionSummary>
            <AccordionDetails>
              <section className={styles["premio-container"]}>
                <TabPremio1
                  premio={premio.slice(8, 10)}
                  getPostulaciones={getPostulaciones}
                  postulaciones={postulaciones}
                />
              </section>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            style={{ zIndex: "999" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <h2 className={styles["titulo"]}>
                Premio Especial de Ciencia y Tecnología
              </h2>
            </AccordionSummary>
            <AccordionDetails>
              <section className={styles["premio-container"]}>
                <TabPremio1
                  premio={premio.slice(10, 13)}
                  getPostulaciones={getPostulaciones}
                  postulaciones={postulaciones}
                />
              </section>
            </AccordionDetails>
          </Accordion>
        </Container>
      </CardNormal>
    </section>
  );
};

export default Dashboard;
