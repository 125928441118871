import React from 'react';

import Mencion45678 from './Mencion45678';

const Mencion4 = ({ getPostulaciones, postulaciones }: any) => {
  const premioId = '2';
  const mencionId = '4';
  const tittle = 'ciencias naturales';
  /* 

Arrastrar los siguientes documentos en formato PDF: cédula, coautores, titulo doctoral, documentoAcreditable, postulación de productos o click en <span class="filepond--label-action">Buscar Archivos</span>*/
  return (
    <Mencion45678
      premioId={premioId}
      mencionId={mencionId}
      tittle={tittle}
      postulaciones={postulaciones}
      getPostulaciones={getPostulaciones}
    />
  );
};

export default Mencion4;
