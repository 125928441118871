import { Box, Stack, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { a11yProps, handleChangeTab } from "./TabLogic";
import TabPanel from "./TabPanel";
import { RenderFormularies } from "components/Formularies";
import { RenderContent } from "components/RenderContent";
import IconButton from "@mui/material/IconButton";

const TabPremio1 = ({ premio, getPostulaciones, postulaciones }: any) => {
  const [value, setValue] = useState(0);

  return (
    <>
      <Box sx={{ minWidth: { xs: "100%" }, bgcolor: "background.paper" }}>
        <Tabs
          sx={{
            "& .MuiTabs-flexContainer": {
              flexWrap: "wrap",
              alignItems: "flex-end",
            },
          }}
          TabIndicatorProps={{ sx: { sm: { display: "none" } } }}
          scrollButtons
          orientation="vertical"
          variant="scrollable"
          value={value}
          aria-label="tabs"
          onChange={(e, newValue) => handleChangeTab(e, newValue, setValue)}
        >
          {premio?.map((premio: any, idx: any) => (
            <Tab
              sx={{
                "&": {
                  textTransform: "unset",
                  fontSize: "16px",
                  width: "100%",
                  letterSpacing: "1px",
                  alignItems: "flex-end !important",
                  textAlign: "end !important",
                  fontFamily:'ralewayBold'
                },
              }}
              wrapped
              label={premio?.name}
              key={`${premio?.id}_${idx}`}
              {...a11yProps(premio?.id as any)}
            />
          ))}
        </Tabs>
      </Box>
      <div>
        {premio.map((premio: any, i: any) => {
          return (
            <TabPanel key={premio.id} value={value} index={i}>
              <RenderFormularies
                items={premio}
                renderContent={(item: any) => {
                  RenderContent(item, getPostulaciones, postulaciones);
                }}
                getPostulaciones={getPostulaciones}
                postulaciones={postulaciones}
              />
            </TabPanel>
          );
        })}
      </div>
    </>
  );
};

export default TabPremio1;
