import React, { useState } from "react";
import FileZone from "../Fields/FileZone";
import { CustomButton } from "components/CustomButton";
import { Checkbox, Grid, Stack, Tooltip } from "@mui/material";
import { getRegisterMencionServices } from "services/upload";
import { TPremios1 } from "types/files";
import { useAlert } from "hooks/useAlert";
import { TOpenModals } from "types";
import { DialogConfirm } from "components/DialogConfirm";
import { DIALOG } from "../../constants";
import { LightTooltip } from "components/ToolTip/ToolTip";
import style from "./FormMenciones.module.scss";
import TextTerminos from "components/TextTerminos";
const errorDefault = {
  cedula: true,
  carta: true,
  titulo: true,
  resumen: true,
  listado: true,
};

const Mencion1 = ({ getPostulaciones, postulaciones }: any) => {
  const [cedula, setCedula] = useState<any>([]);
  const [titulo, setTitulo] = useState<any>([]);
  const [resumen, setResumen] = useState<any>([]);
  const [listado, setListado] = useState<any>([]);
  const [statusTerms, setStatusTerms] = useState<boolean>(false);

  const [openModal, setOpenModal] = React.useState<TOpenModals>({
    dialogSubmit: false,
    dialogTerms: false,
  });

  const [error, setError] = useState<Partial<TPremios1>>({});
  const [errorsFiles, setErrorsFiles] = useState<any>([]);
  const [loadingService, setLoadingService] = useState(false);
  const { openAlert } = useAlert();

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const data = new FormData();

    try {
      setLoadingService(true);
      if (
        !cedula.length ||
        !titulo.length ||
        !resumen.length ||
        !listado.length ||
        errorsFiles.length
      ) {
        throw new Error("Necesario");
      }

      data.append("cedula", cedula[0]?.file);
      data.append("titulo", titulo[0]?.file);
      data.append("curriculum", resumen[0]?.file);
      data.append("listaPructos", listado[0]?.file);
      data.append("premioId", "1");
      data.append("mencionId", "1");

      await getRegisterMencionServices({ data, authRequire: true });

      setCedula([]);
      setTitulo([]);
      setResumen([]);
      setListado([]);

      openAlert(
        "Se registró exitosamente a la mención novel investigador o novel investigadora"
      );
    } catch (error: any) {
      setError(errorDefault);
      openAlert(
        error.response
          ? error?.response?.data?.msj
          : "llenar los campos requeridos",
        { variant: "error" }
      );
    } finally {
      setLoadingService(false);
      getPostulaciones();
    }
  };

  const onDialog = (e: any) => {
    e.preventDefault();
    setOpenModal({ dialogSubmit: true });
  };

  const onDialogTerms = (e: any) => {
    e.preventDefault();
    setOpenModal({ dialogTerms: true });
  };

  function termsAccept() {
    setStatusTerms(true);
  }

  return (
    <section>
      <form onSubmit={postulaciones.length ? onDialog : onSubmit}>
        <Stack spacing={2}>
          <Grid container spacing={4} sx={{ fontFamily: "ronniaExtraBold" }}>
            <LightTooltip title="Haga click para cargar la copia de cédula de identidad en formato PDF">
              <Grid item xs={12} sm={4} alignSelf={"flex-end"}>
                <h4 className={style["title"]}>
                  *Copia de cédula de identidad
                </h4>

                <FileZone
                  files={cedula}
                  setFiles={setCedula}
                  typeFiles="pdf"
                  label={"Copia de cédula de identidad"}
                  labelError={"Campo requerido"}
                  error={error}
                  name="cedula"
                  setError={setError}
                  //errores de extension:
                  errorsFiles={errorsFiles}
                />
              </Grid>
            </LightTooltip>
            <LightTooltip title="Haga click para cargar el título universitario en formato PDF (6mb max)">
              <Grid item xs={12} sm={4} alignSelf={"flex-end"}>
                <h4 className={style["title"]}> *Título universitario</h4>
                <FileZone
                  files={titulo}
                  setFiles={setTitulo}
                  typeFiles="pdf"
                  maxFileSize="6MB"
                  label={"Título universitario"}
                  labelError={"Campo requerido"}
                  error={error}
                  name="titulo"
                  setError={setError}
                  errorsFiles={errorsFiles}
                />
              </Grid>
            </LightTooltip>
            <LightTooltip title="Haga click para cargar el resumen curricular en formato PDF (6mb max)">
              <Grid item xs={12} sm={4} alignSelf={"flex-end"}>
                <h4 className={style["title"]}>*Resumen curricular</h4>
                <FileZone
                  files={resumen}
                  setFiles={setResumen}
                  typeFiles="pdf"
                  maxFileSize="6MB"
                  label={"Resumen curricular"}
                  labelError={"Campo requerido"}
                  error={error}
                  name="resumen"
                  setError={setError}
                  errorsFiles={errorsFiles}
                />
              </Grid>
            </LightTooltip>
            <LightTooltip title="Haga click para cargar el listado de publicaciones en formato PDF (6mb max)">
              <Grid item xs={12} sm={4} alignSelf={"flex-end"}>
                <h4 className={style["title"]}>*Listado de publicaciones</h4>
                <FileZone
                  files={listado}
                  setFiles={setListado}
                  typeFiles="pdf"
                  maxFileSize="6MB"
                  label={"Listado de publicaciones"}
                  labelError={"Campo requerido"}
                  error={error}
                  name="listado"
                  setError={setError}
                  errorsFiles={errorsFiles}
                />
              </Grid>
            </LightTooltip>
          </Grid>
          <Grid item xs={12} display={"flex"}>
            <Checkbox
              checked={statusTerms}
              onChange={(status) => {
                setStatusTerms(status.target.checked);
              }}
            />
            <p>
              He leído y acepto los Términos y Condiciones{" "}
              <span
                onClick={onDialogTerms}
                style={{
                  color: "#0a307e",
                  cursor: "pointer",
                  textDecorationLine: "underline",
                }}
                className="paragraph"
              >
                click para leerlos
              </span>
            </p>
          </Grid>
          <Stack alignItems="flex-end">
            <CustomButton
              label="Postular"
              typeVariant="outlined"
              typeAction="submit"
              disabled={statusTerms ? false : true}
              disabledReload={statusTerms ? false : true}
            />
          </Stack>
        </Stack>
      </form>
      <DialogConfirm
        onSubmit={termsAccept}
        title={<span className="titles">Terminos y Condiciones</span>}
        openModal={openModal.dialogTerms}
        setOpenModal={setOpenModal}
      >
        <TextTerminos />
      </DialogConfirm>
      <DialogConfirm
        onSubmit={onSubmit}
        title={DIALOG.titulo}
        openModal={openModal.dialogSubmit}
        setOpenModal={setOpenModal}
      >
        {DIALOG.descripcion}
      </DialogConfirm>
    </section>
  );
};

export default Mencion1;
