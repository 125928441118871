import React, { useState } from "react";
import FileZone from "../Fields/FileZone";
import style from "./FormMenciones.module.scss";
import { CustomButton } from "components/CustomButton";
import { Checkbox, Grid, Stack, Tooltip } from "@mui/material";
import { getRegisterMencionServices } from "services/upload";
import { TextField } from "components/Fields";
import { useFormHook } from "hooks/useFormHook";
import { DIALOG, RULES } from "constants/index";
import { TPremios1 } from "types/files";
import { useAlert } from "hooks/useAlert";
import { useSearchParams } from "react-router-dom";
import { CustomSwitch } from "components/CustomSwitch";

import { DialogConfirm } from "components/DialogConfirm";
import { TOpenModals } from "types";
import { LightTooltip } from "components/ToolTip/ToolTip";
import TextTerminos from "components/TextTerminos";
const errorDefault = {
  postulacion: true,
  cedula: true,
  copiaTrabajo: true,
  documentExel: true,
};

const initialDatarow = {
  titulo: "",
  resumen: "",
  fechaPublicacion: "",
  fechaAceptacion: "",
  direccion:''
};

const Mencion45678 = ({
  premioId,
  mencionId,
  tittle,
  getPostulaciones,
  postulaciones,
}: any) => {
  const [postulacion, setPostulacion] = useState<any>([]);
  const [cedula, setCedula] = useState<any>([]);
  const [documentExel, setDocumentExel] = useState<any>([]);
  const [documentoAcreditable, setDocumentoAcreditable] = useState<any>([]);
  const [copiaTrabajo, setCopiaTrabajo] = useState<any>([]);
  const [groups, setGroups] = useState<any>(false);
  const [statusTerms, setStatusTerms] = useState<boolean>(false);
  const [loadingService, setLoadingService] = useState(false);
  const [error, setError] = useState<Partial<TPremios1>>({});
  const [errorsFiles, setErrorsFiles] = useState<any>([]);
  const { openAlert } = useAlert();

  const [openModal, setOpenModal] = React.useState<TOpenModals>({
    dialogSubmit: false,
    dialogTerms:false
  });


  const { control, handleSubmit, errors, reset } = useFormHook(initialDatarow);

  const onSubmit = async (datos: any) => {
    const data = new FormData();
    
    try {
      setLoadingService(true);
      
      if (groups) {
        if (!postulacion.length || !documentExel.length) {
          throw new Error("");
        }
      }

      
      if (
        !cedula.length ||
        !copiaTrabajo.length ||
        errorsFiles.length ||
        !documentoAcreditable.length
        ) {
          throw new Error("cedula");
        }



      data.append("cedula", cedula[0]?.file);
      data.append("cartaPostulacion", postulacion[0]?.file);
      data.append("documentExel", documentExel[0]?.file);
      data.append("grupal", groups);
      data.append("documentoAcreditable", documentoAcreditable[0]?.file);
      data.append("copiaTrabajo", copiaTrabajo[0]?.file);
      data.append("titulo", datos.titulo);
      data.append("direccion", datos.direccion);
      data.append("resumen", datos.resumen);
      data.append("fechaPublicacion", datos.fechaPublicacion);
      data.append("fechaAceptacion", datos.fechaAceptacion);
      data.append("premioId", premioId);
      data.append("mencionId", mencionId);
      
      if (!groups) {
        data.delete("documentExel");
      }
      
      if (!groups) {
        data.delete("cartaPostulacion");
      }
      
       await getRegisterMencionServices({ data, authRequire: true });

      setCedula([]);
      setPostulacion([]);
      setDocumentExel([]);
      setDocumentoAcreditable([]);
      setCopiaTrabajo([]);
      reset(initialDatarow);

      openAlert(`Se registró exitosamente a la mención ${tittle}`);
    } catch (error: any) {
      setError(errorDefault);

      openAlert(
        error.response
          ? error?.response?.data?.msj
          : "llenar los campos requeridos",
        { variant: "error" }
      );
    } finally {
      setLoadingService(false);
      getPostulaciones();
    }
  };

  const onDialog = (e: any) => {
    e.preventDefault();
    setOpenModal({ dialogSubmit: true });
  };

  const onDialogTerms = (e: any) => {
    e.preventDefault();
    setOpenModal({ dialogTerms: true });
  };

  function termsAccept() {
    setStatusTerms(true);
  }

  return (
    <section>
      <form onSubmit={postulaciones.length ? onDialog : handleSubmit(onSubmit)}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            marginBottom: '23px'
          }}
        >
          <div>
            <h4>Tipo de postulación</h4>
            <CustomSwitch groups={groups} setGroups={setGroups} />
          </div>
        </div>
        <Stack spacing={2}>
          <Grid
            container
            spacing={4}
            sx={{ position: "relative", fontFamily: "ronniaExtraBold" }}
          >
            {groups && <LightTooltip title="Haga click para cargar la carta de postulación del trabajo en formato  PDF">
              <Grid
                item
                xs={12}
                sm={6}
              >
                <h4 className={style["title"]}>Carta de postulación del trabajo</h4>

                <FileZone
                  files={postulacion}
                  setFiles={setPostulacion}
                  typeFiles="pdf"
                  label={"Carta de postulación del trabajo"}
                  labelError={"Campo requerido"}
                  error={error}
                  name="postulacion"
                  setError={setError}
                  errorsFiles={errorsFiles}
                />
              </Grid>
            </LightTooltip>}
            <LightTooltip title="Haga click para cargar la copia de cédula de identidad del autor o autora responsable del equipo en formato PDF">
              <Grid item xs={12} sm={6} >
                <h4 className={style["title"]}>Copia de cédula de identidad</h4>
                <FileZone
                  files={cedula}
                  setFiles={setCedula}
                  typeFiles="pdf"
                  label={"Copia de cédula de identidad del autor o autora"}
                  labelError={"Campo requerido"}
                  error={error}
                  name="cedula"
                  setError={setError}
                  errorsFiles={errorsFiles}
                />
              </Grid>
            </LightTooltip>
            {groups && <LightTooltip title="Haga click para cargar los datos básicos de coautores o coautoras en formato PDF, XLS, ODS">
              <Grid
                item
                xs={12}
                sm={6}

              >
                <h4 className={style["title"]}>Datos básicos de coautores o coautoras</h4>
                <FileZone
                  files={documentExel}
                  setFiles={setDocumentExel}
                  typeFiles="pdf/xlsx/ods"
                  label={"Datos básicos de los coautores o coautoras"}
                  labelError={"Campo requerido"}
                  error={error}
                  name="documentExel"
                  setError={setError}
                  errorsFiles={errorsFiles}
                />
              </Grid>
            </LightTooltip>}

            <Grid item xs={12} sm={6}>
              <h4 className={style["title"]}>*Título del trabajo</h4>
              <TextField
                name="titulo"
                label="Título"
                control={control}
                variant="standard"
                rules={RULES.titulo}
                error={Boolean(errors.titulo)}
                errmsg={errors.titulo}
                multiline
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <h4 className={style["title"]}>*Resumen del trabajo</h4>
              <TextField
                name="resumen"
                label="Resumen"
                control={control}
                variant="standard"
                rules={RULES.resumen}
                error={Boolean(errors.resumen)}
                errmsg={errors.resumen}
                multiline
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <h4 className={style["title"]}>
                Fecha de publicación del trabajo (no obligatorio)
              </h4>
              <TextField
                name="fechaPublicacion"
                label="Fecha de publicación del trabajo"
                control={control}
                variant="standard"
                type="date"
                labelProps={{ shrink: true }}
                error={Boolean(errors.fechaPublicacion)}
                errmsg={errors.fechaPublicacion}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <h4 className={style["title"]}>
                *Fecha de aceptación del trabajo para su publicación
                (obligatorio)
              </h4>
              <TextField
                name="fechaAceptacion"
                label="Fecha de aceptación del trabajo"
                control={control}
                variant="standard"
                type="date"
                labelProps={{ shrink: true }}
                rules={RULES.required}
                error={Boolean(errors.fechaAceptacion)}
                errmsg={errors.fechaAceptacion}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <h4 className={style["title"]}>
              Dirección URL del trabajo publicado (no obligatorio)
              </h4>
              <TextField
                name="direccion"
                label="Dirección URL"
                control={control}
                variant="standard"
                error={Boolean(errors.direccion)}
                errmsg={errors.direccion}
           
              />
              
            </Grid>

            <LightTooltip title="Haga click para cargar la carta que acredita la aceptación del trabajo para su publicación (en caso de no haber sido publicado a la fecha) en formato PDF. ">
              <Grid item xs={12} sm={6} alignSelf={"flex-end"}>
                <h4 className={style["title"]}>
                  *Carta que acredita la aceptación del trabajo para su
                  publicación (obligatorio)
                </h4>

                <FileZone
                  files={documentoAcreditable}
                  setFiles={setDocumentoAcreditable}
                  typeFiles="pdf"
                  label={"Carta que acredita la aceptación del trabajo"}
                  labelError={"Campo requerido"}
                  error={error}
                  name="documentoAcreditable"
                  setError={setError}
                  errorsFiles={errorsFiles}
                />
              </Grid>
            </LightTooltip>
            <LightTooltip title="Haga click para cargar la Copia del trabajo en formato PDF (10mb max)">
              <Grid item xs={12} sm={6} alignSelf={"flex-end"}>
                <h4 className={style["title"]}>Copia del trabajo</h4>
                <FileZone
                  files={copiaTrabajo}
                  setFiles={setCopiaTrabajo}
                  typeFiles="pdf"
                  maxFileSize="10MB"
                  label={"Copia del trabajo"}
                  labelError={"Campo requerido"}
                  error={error}
                  name="copiaTrabajo"
                  setError={setError}
                  errorsFiles={errorsFiles}
                />
              </Grid>
            </LightTooltip>
            </Grid>
            <Grid item xs={12} display={"flex"}>
            <Checkbox
              checked={statusTerms}
              onChange={(status: any) => {
                setStatusTerms(status.target.checked);
              }}
            />
            <p>
              He leído y acepto los Términos y Condiciones{" "}
              <span
                onClick={onDialogTerms}
                style={{
                  color: "#0a307e",
                  cursor: "pointer",
                  textDecorationLine: "underline",
                }}
                className="paragraph"
              >
                click para leerlos
              </span>
            </p>
       
          </Grid>

          <Stack alignItems="flex-end">
          <CustomButton
              label="Postular"
              typeVariant="outlined"
              typeAction="submit"
              disabled={statusTerms ? false : true}
              disabledReload={statusTerms ? false : true}
            />
          </Stack>
        </Stack>
      </form>
      <DialogConfirm
        onSubmit={termsAccept}
        title={<span className="titles">Terminos y Condiciones</span>}
        openModal={openModal.dialogTerms}
        setOpenModal={setOpenModal}
      >
        <TextTerminos />
      </DialogConfirm>
      <DialogConfirm
        onSubmit={handleSubmit(onSubmit)}
        title={DIALOG.titulo}
        openModal={openModal.dialogSubmit}
        setOpenModal={setOpenModal}
      >
        {DIALOG.descripcion}
      </DialogConfirm>
    </section >
  );
};

export default Mencion45678;
