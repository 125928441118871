import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }: any) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#81cbff",
      color: '#646262',
      boxShadow: theme.shadows[1],
      fontSize: 16,
      fontFamily:"ralewayRegular",
      letterSpacing:"0.5px"
    },
  }));