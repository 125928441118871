import { useState, useEffect } from "react";

import {

  FormDashboard,

} from "components/index";
import { localToken } from "constants/index";


import { useAlert } from "hooks/useAlert";
import { searchItemLocal } from "utils/helpers";
import NAVIGATION from "routes/navigation";
import { useNavigate } from "react-router";
import { getRegisterServices } from "services/auth";


const initialDatarow = {
  id: "",
  email: "",
  firstName: "",
  secondName: "",
  surname: "",
  secondSurName: "",
  birthDate: "",
  maritalStatusId: "",
  cellPhone: "",
  sex: "",
  rif: "",
  parroquiaId: "",
  officePhone: "",
  homePhone: "",
  educationLevelId: "",
  profession: "",
  laborOrganization: "",
  post: "",
  nationality: "V",
  identificationCard: "",
  password: "",
  password2: "",
  direction: "",
  estadoId: "",
  municipioId: "",
  universityName: ""
};

const Register = () => {
  const [loadingService, setLoadingService] = useState<boolean>(false);
  const [entesOptions, setEntesOptions] = useState([]);
  const [captcha, setCaptcha] = useState(null);
  const token = searchItemLocal(localToken);
  const navigate = useNavigate();
  const { openAlert } = useAlert();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [rowData, setRowData] = useState({
    data: initialDatarow,
    isNewRecord: true,
  });

  const onSubmit = async (data: any, { referenciaBancaria, fotoCarnet }: any) => {
    if (captcha !== null) {
      try {
        delete data.password2;
        const { email, password, personRif, rif, ...restData } = data;
        /*    const datos = {
             user: { email, password },
             pi: restData,
           }; */

        const formData: any = new FormData()

        formData.append('user', JSON.stringify({ email, password }))
        formData.append('pi', JSON.stringify({ ...restData, rif: `${personRif}${rif}` }))
        /*  formData.append('referenciaBancaria', referenciaBancaria)
         formData.append('fotoCarnet', fotoCarnet) */

        setIsLoading(true);
        await getRegisterServices({ formData });
        openAlert("Se registró exitosamente");
        navigate("/iniciar-sesion", { replace: true, },);
      } catch (error) {
        const err = error as any;

        openAlert(err.response.data.msj, {
          variant: "error",
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      openAlert("complete el reCAPTCHA", { variant: "error" });
    }
  };

  useEffect(() => {
    if (token) navigate(NAVIGATION.HOME);
  }, [token, navigate]);

  return (

    <>
      <FormDashboard
        onSubmit={onSubmit}
        rowData={rowData}
        loadingService={loadingService}
        setCaptcha={setCaptcha}
        captcha={captcha}
      />
    </>
  );
};

export default Register;
