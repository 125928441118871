import { Stack } from '@mui/material';
import { RenderContent } from 'components/RenderContent';

import React from 'react';

const RenderFormularies: React.FC<FormQuestionsProps> = ({
  items,
  getPostulaciones,
  renderContent,
  postulaciones
}) => {
  if (!items) return null;

  return (
    <Stack gap={2}>
      {/* Mostrar contenido extra */}
      {typeof renderContent === 'function' &&
        RenderContent(items, getPostulaciones, postulaciones)}
    </Stack>
  );
};

export type FormQuestionsProps = {
  items: any;
  renderContent?: any;
  getPostulaciones: any;
  postulaciones: any;
};
export default RenderFormularies;
