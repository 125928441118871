import React, { useState } from 'react';
import FileZone from '../Fields/FileZone';
import { CustomButton } from 'components/CustomButton';
import { Container, Grid, Stack } from '@mui/material';
import { getRegisterMencionServices } from 'services/upload';
import { TextField } from 'components/Fields';
import { useFormHook } from 'hooks/useFormHook';
import { RULES } from 'constants/index';
import { TPremios1 } from 'types/files';
import { useAlert } from 'hooks/useAlert';
import { useSearchParams } from 'react-router-dom';
import Mencion45678 from './Mencion45678';

const Mencion7 = ({ getPostulaciones, postulaciones }: any) => {
  const premioId = '2';
  const mencionId = '7';
  const tittle = 'ciencias sociales y humanas';

  /* 

Arrastrar los siguientes documentos en formato PDF: cédula, coautores, titulo doctoral, documentoAcreditable, postulación de productos o click en <span class="filepond--label-action">Buscar Archivos</span>*/
  return (
    <Mencion45678
      premioId={premioId}
      mencionId={mencionId}
      tittle={tittle}
      postulaciones={postulaciones}
      getPostulaciones={getPostulaciones}
    />
  );
};

export default Mencion7;
