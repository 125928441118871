import React, { useState } from "react";
import ReactDOM from "react-dom";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import "../../styles/globals.css";
// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FieldsTypesAccepted from "./FieldsTypesAccepted";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import { Tooltip } from "@mui/material";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);

const FileZone = ({
  files,
  setFiles,
  label,
  labelError,
  error = false,
  name,
  setError = () => null,
  maxFiles = 1,
  maxFileSize = "2MB",
  allowMultiple = false,
  typeFiles,
  errorsFiles,
}: any) => {
  const filterOptions = () => {
    const name = errorsFiles.filter(
      (errorFilter: any) => files[0]?.filename === errorFilter
    )[0];
    const index = errorsFiles.findIndex((nameError: any) => name === nameError);
    return { name, index };
  };

  return (
    <div className={error[name] ? "archivos errors-archivos" : "archivos"} >
      <FilePond
        onerror={(file, error) => {
          errorsFiles.push(error?.filename);
        }}
        files={files}
        allowMultiple={allowMultiple}
        allowReorder={true}
        onupdatefiles={(filesI) => {
          delete error[name];
          setError(error);
          setFiles(filesI);
        }}
        maxFiles={allowMultiple ? maxFiles : null}
        maxFileSize={maxFileSize}
        onremovefile={() => {
          setError({ ...error, [name]: "true" });
          const nombre = files[0]?.filename;
          if (filterOptions().name === nombre) {
            errorsFiles.splice(filterOptions().index, 1);
          }
        }}
        labelFileTypeNotAllowed={"El tipo de archivo no es valido"}
        fileValidateTypeLabelExpectedTypes={`El tipo de archivo no esta permitido`}
        labelMaxFileSizeExceeded="El archivo supera el limite de tamaño maximo"
        labelMaxFileSize="El limite de tamaño del archivo es de {filesize}"
        acceptedFileTypes={FieldsTypesAccepted(typeFiles)}
        labelIdle={error[name] ? labelError : label}
        labelFileLoadError={error[name] ? labelError : false}
        
      />



    </div>
  );
};

export default FileZone;
