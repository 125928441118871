import { Outlet, useLocation } from "react-router-dom";
import styles from "./Login.module.scss";
import image from "../../img/Recurso_19.png";
import Svg1 from "img/svg/Svg1";
import fondo_PremiosCyT_LoginV01 from "img/fondo_PremiosCyT_LoginV01.webp";
import fondoSVG from "img/logo MINCYT-01.png";
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import banner from "img/Asset1.jpg";
import { Grid } from "@mui/material";

const PublicLayout: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [classPage, setClassPage] = useState(false);
  let location = useLocation();
  useEffect(() => {
    if (location.pathname === "/registrar") {
      setClassPage(true);
    } else {
      setClassPage(false);
    }
  }, [location]);

  return (
    <div className={styles.app}>
      <header
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#005b9a",

        }}
      >
        <figure
          style={{
            backgroundColor: "#005b9a",
            margin: "0",
          }}
        >
          <img
            src={banner}
            className={styles.imageLogo}
            alt="fondo_PremiosCyT_LoginV01"
            style={{ maxWidth: "100%", maxHeight: "95px", }}
          />
        </figure>
      </header>
      <div className={styles.background}>
        <Grid
          container
          sx={{ width: "100%" }}
          className={styles.containerForm}
          direction={classPage ? "row-reverse" : "row"}
          alignItems={"flex-start"}
        >
          {/* <Grid item md={1} /> */}
          {/* grid1 */}
          <Grid
            item
            xs={12}
            md={classPage ? 9 : 6}
            justifyContent={"center"}
            sx={{ zIndex: "99" }}
          >
            <figure
              style={{
                zIndex: "999",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '30px'
              }}
            >
              <img
                src={image}
                alt=""
                className={
                  classPage ? styles.registerImage : styles.loginImage
                }
              />
            </figure>
            <div
              className={
                classPage ? styles.registerClass : styles.centerWrapper
              }
            >
              <div>{children ? children : <Outlet />}</div>
            </div>
          </Grid>
          {/* className={classPage ? styles.registerClass : styles.centerWrapper} */}
          {/* grid2 */}
          <Grid item xs={12} md={classPage ? 3 : 6}>
            {/* <div
              style={{
                userSelect: "none",
                zIndex: "99",
                display: "flex",
                justifyContent: "start",
              }}
            >
              <figure
                style={{
                  zIndex: "999",
                }}
              >
                <img
                  src={image}
                  alt=""
                  className={
                    classPage ? styles.registerImage : styles.loginImage
                  }
                />
              </figure>
              !classPage && (
                <p>Lorem ipsum dolor sit amet, consectetuer 
                adipiscing elit, sed diam nonummy nibh 
                euismod tincidunt ut laoreet dolore magna 
                aliquam erat volutpat.</p>
              )
            </div> */}
          </Grid>
        </Grid>
      </div>
      {/* <figure className="borde">
        <img src={fondoSVG} alt="" />
      </figure> */}
    </div>
  );
};

export default PublicLayout;
