import { request } from 'lib/request';

//register
export function getRegisterMencionServices({ data, authRequire }: any) {
  return request('/postulaciones', {
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      type: 'formData'
    },
    authRequire,
    data: data
  });
}
