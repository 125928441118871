import { request } from 'lib/request';

// get all Activities
export function getLoginServices({ data, authRequire }: any) {
  return request('/singin', {
    method: 'post',
    authRequire,
    data: data
  });
}

//register
export function getRegisterServices({ formData, authRequire }: any) {
  return request('/singup', {
    method: 'post',
    authRequire,
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      type: 'formData'
    },
    data: formData
  });
}
