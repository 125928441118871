import {
  useState,
  FunctionComponent,
  PropsWithChildren,
  useEffect,
} from "react";
import "styles/layout.css";
import { Outlet, useNavigate } from "react-router-dom";
import styles from "styles/Layout.module.scss";
import fondoSVG from "img/logo MINCYT-01.png";
import { DrawerMenu } from "components/index";
import { styled } from "@mui/material/styles";
import { removeItemLocal, searchItemLocal } from "utils/helpers";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import NAVIGATION from "routes/navigation";
import { localToken } from "constants/index";
import banner from "img/Asset1.jpg";
import { Typography } from "@mui/material";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  //  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "-75x",
  }),
}));

const MainLayout: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const token = searchItemLocal(localToken);
  useEffect(() => {
    if (!token) navigate(NAVIGATION.LOGIN);
  }, [token, navigate]);

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logOut = () => {
    removeItemLocal(localToken);
    navigate(NAVIGATION.LOGIN);
  };

  return (
    <>
      <header
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#005b9a",
          position: "relative",
          alignItems: "center",
        }}
        className={styles["header"]}
      >
        <figure
          style={{
            margin: "0"
          }}
        >
          <img src={banner} alt="" style={{ maxWidth: "100%", maxHeight:'70px' }} />
        </figure>

        <IconButton
          onClick={() => logOut()}
          aria-label="cerrar sesión"
          color="secondary"
          className={styles["buttonExit"]}
        >
          <Typography sx={{ fontFamily: "ralewayBold" }}>Salir</Typography>
          <LogoutIcon />
        </IconButton>
      </header>
      <div className="App" style={{ backgroundColor: "#e8eef4AA" }}>
        <Main open={open}>
          <section className={styles["content"]}>
            {children ? children : <Outlet />}
          </section>
        </Main>
        {/* <figure className="borde">
          <img src={fondoSVG} alt="" />
        </figure> */}
      </div>
    </>
  );
};

export default MainLayout;
