import React, { useState } from "react";
import FileZone from "../Fields/FileZone";
import { CustomButton } from "components/CustomButton";
import { Checkbox, Grid, Stack, Tooltip } from "@mui/material";
import { getRegisterMencionServices } from "services/upload";
import { TPremios1 } from "types/files";
import { useAlert } from "hooks/useAlert";
import { DialogConfirm } from "components/DialogConfirm";
import { TOpenModals } from "types";
import { DIALOG } from "../../constants";
import { LightTooltip } from "components/ToolTip/ToolTip";
import style from "./FormMenciones.module.scss";
import TextTerminos from "components/TextTerminos";
const errorDefault = {
  cedula: true,
  carta: true,
  titulo: true,
  listadoPost: true,
  listado: true,
};
const Mencion3 = ({ getPostulaciones, postulaciones }: any) => {
  const [cedula, setCedula] = useState<any>([]);
  const [carta, setCarta] = useState<any>([]);
  const [openModal, setOpenModal] = React.useState<TOpenModals>({
    dialogSubmit: false,
    dialogTerms:false,
  });
  const [statusTerms, setStatusTerms] = useState<boolean>(false);

  const [listadoPost, setListadoPost] = useState<any>([]);
  const [listado, setListado] = useState<any>([]);
  const [error, setError] = useState<Partial<TPremios1>>({});
  const [errorsFiles, setErrorsFiles] = useState<any>([]);
  const [loadingService, setLoadingService] = useState(false);
  const { openAlert } = useAlert();

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const data = new FormData();

    try {
      setLoadingService(true);

      if (
        !cedula.length ||
        !listadoPost.length ||
        !listado.length ||
        !carta.length ||
        errorsFiles.length
      ) {
        throw new Error("cedula");
      }
      data.append("cedula", cedula[0]?.file);
      data.append("listaPructos", listadoPost[0]?.file);
      data.append("listaPostulados", listado[0]?.file);
      data.append("cartaPostulacion", carta[0]?.file);
      data.append("premioId", "1");
      data.append("mencionId", "3");

      await getRegisterMencionServices({ data, authRequire: true });

      setCedula([]);
      setCarta([]);
      setListadoPost([]);
      setListado([]);

      openAlert(
        "Se registró exitosamente a la mención grupo de investigación consolidado"
      );
    } catch (error: any) {
      setError(errorDefault);
      openAlert(
        error.response
          ? error?.response?.data?.msj
          : "llenar los campos requeridos",
        { variant: "error" }
      );
    } finally {
      setLoadingService(false);
      getPostulaciones();
    }
  };
  const onDialog = (e: any) => {
    e.preventDefault();
    setOpenModal({ dialogSubmit: true });
  };

  const onDialogTerms = (e: any) => {
    e.preventDefault();
    setOpenModal({ dialogTerms: true });
  };

  function termsAccept() {
    setStatusTerms(true);
  }
  return (
    <section>
      <form onSubmit={postulaciones.length ? onDialog : onSubmit}>
        <Stack spacing={2}>
          <Grid container spacing={4} sx={{ fontFamily: "ronniaExtraBold" }}>
            <LightTooltip title="Haga click para cargar la copia de cédula de identidad en formato PDF">
              <Grid item xs={12} sm={6} alignSelf={"flex-end"}>
                <h4 className={style["title"]}>
                  *Copia de cédula de identidad
                </h4>
                <FileZone
                  files={cedula}
                  setFiles={setCedula}
                  typeFiles="pdf"
                  label={"Copia de cédula de identidad"}
                  labelError={
                    'Campo requerido'
                  }
                  error={error}
                  name="cedula"
                  setError={setError}
                  errorsFiles={errorsFiles}
                />
              </Grid>
            </LightTooltip>
            <LightTooltip title="Haga click para cargar el listado de miembros de grupo en formato PDF ">
              <Grid item xs={12} sm={6} alignSelf={"flex-end"}>
                <h4 className={style["title"]}>
                  *Listado de miembros de grupo
                </h4>
                <FileZone
                  files={listadoPost}
                  setFiles={setListadoPost}
                  typeFiles="pdf"
                  label={"Listado de miembros de grupo"}
                  labelError={
                    'Campo requerido'
                  }
                  error={error}
                  name="listadoPost"
                  setError={setError}
                  errorsFiles={errorsFiles}
                />
              </Grid>
            </LightTooltip>
            <LightTooltip title="Haga click para cargar la carta de postulación en formato PDF">
              <Grid item xs={12} sm={6} alignSelf={"flex-end"}>
                <h4 className={style["title"]}>*Carta de postulación</h4>
                <FileZone
                  files={carta}
                  setFiles={setCarta}
                  typeFiles="pdf"
                  label={"Carta de postulación"}
                  labelError={
                    'Campo requerido'
                  }
                  error={error}
                  name="carta"
                  setError={setError}
                  errorsFiles={errorsFiles}
                />
              </Grid>
            </LightTooltip>
            <LightTooltip title="Haga click para cargar el listado de productos de investigación en formato PDF (10mb max)">
              <Grid item xs={12} sm={6} alignSelf={"flex-end"}>
                <h4 className={style["title"]}>
                  *Listado de productos de investigación
                </h4>
                <FileZone
                  files={listado}
                  setFiles={setListado}
                  typeFiles="pdf"
                  maxFileSize="10MB"
                  label={"Listado de productos de investigación"}
                  labelError={
                    'Campo requerido'
                  }
                  error={error}
                  name="listado"
                  setError={setError}
                  errorsFiles={errorsFiles}
                />
              </Grid>
            </LightTooltip>
          </Grid>

          <Grid item xs={12} display={"flex"}>
            <Checkbox
              checked={statusTerms}
              onChange={(status: any) => {
                setStatusTerms(status.target.checked);
              }}
            />
            <p>
              He leído y acepto los Términos y Condiciones{" "}
              <span
                onClick={onDialogTerms}
                style={{
                  color: "#0a307e",
                  cursor: "pointer",
                  textDecorationLine: "underline",
                }}
                className="paragraph"
              >
                click para leerlos
              </span>
            </p>
          </Grid>

          <Stack alignItems="flex-end">
            <CustomButton
              label="Postular"
              typeVariant="outlined"
              typeAction="submit"
              disabled={statusTerms ? false : true}
              disabledReload={statusTerms ? false : true}
            />
          </Stack>
        </Stack>
      </form>

      <DialogConfirm
        onSubmit={termsAccept}
        title={<span className="titles">Terminos y Condiciones</span>}
        openModal={openModal.dialogTerms}
        setOpenModal={setOpenModal}
      >
        <TextTerminos />
      </DialogConfirm>

      <DialogConfirm
        onSubmit={onSubmit}
        title={DIALOG.titulo}
        openModal={openModal.dialogSubmit}
        setOpenModal={setOpenModal}
      >
        {DIALOG.descripcion}
      </DialogConfirm>
    </section>
  );
};

export default Mencion3;
