import React from "react";

const FieldsTypesAccepted = (type: any) => {
  switch (type) {
    case "image":
      return ["image/*"];
    case "pdf":
      return ["application/pdf"];
    case "xlsx":
      return [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
    case "pdf/xlsx":
      return [
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      case "pdf/xlsx/ods":
        return [
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.oasis.opendocument.spreadsheet"
        ];
    case "mp4":
      return [
        "video/mp4"
      ];
  }
};

export default FieldsTypesAccepted;
