import React from "react";

const TextTerminos = () => {
  return (
    <>
      <span className="paragraph">
        Entiendo y acepto las normas establecidas en el Reglamento Operativo de
        los Premios Nacionales de Ciencia, Tecnología e Innovación emitido por
        la Ministra del Poder Popular para la Ciencia y Tecnología mediante
        resolución 216, de fecha 9 de junio de 2023, publicada en Gaceta oficial
        nº 42.662 de fecha 30 de junio de 2023, así como las bases
        correspondientes y demás lineamientos y decisiones que en materia de los
        Premios Nacionales en su edición 2023 emitan las autoridades del
        Ministerio del Poder Popular para la Ciencia y Tecnología.
      </span>
    </>
  );
};

export default TextTerminos;
