import { useState, useEffect, useRef } from "react";
import style from "components/PublicLayout/Login.module.scss";
import { CustomButton, TextField } from "components/index";
import { RULES, localToken } from "constants/index";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormHook } from "hooks/useFormHook";
import { Stack } from "@mui/material";
import { getLoginServices } from "services/auth";
import { useAlert } from "hooks/useAlert";
import { searchItemLocal, setItemLocal } from "utils/helpers";
import NAVIGATION from "routes/navigation";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";

const DEFAULT_VALUES = {
  email: "",
  password: "",
};
const Login = () => {
  const [captcha, setCaptcha] = useState(null);
  const token = searchItemLocal(localToken);
  const navigate = useNavigate();
  const { openAlert } = useAlert();
  const { control, handleSubmit, errors } = useFormHook(DEFAULT_VALUES);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (data: any) => {
    if (captcha !== null) {
      try {
        setIsLoading(true);
        const response = await getLoginServices({ data });
        setItemLocal(localToken, response?.data?.token);
      } catch (error: any) {
        openAlert(error.response.data.msj, { variant: "error" });
      } finally {
        setIsLoading(false);
      }
    } else {
      openAlert("complete el reCAPTCHA", { variant: "error" });
    }
  };

  function onChange(value: any) {
    setCaptcha(value);
  }

  useEffect(() => {
    if (token) navigate(NAVIGATION.HOME);
  }, [token, navigate]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <h1 style={{ fontSize: '30px', textAlign: 'left', color: '#003366' }}>Bienvenido</h1>
        <div style={{
          backgroundColor: "#003366",
          width: '100%',
          height: '3px',
          margin: '0'
        }}></div>
        <TextField
          className={style["input-login"]}
          name="email"
          label="Correo electrónico"
          labelProps={{ sx: { top: "11px" }, shrink: true }}
          control={control}
          variant="standard"
          type="email"
          error={Boolean(errors.username)}
          errmsg={errors.username}
          rules={RULES.required}
        />

        <TextField
          className={style["input-login"]}
          name="password"
          labelProps={{ sx: { top: "11px" }, shrink: true }}
          label="Contraseña"
          control={control}
          type="password"
          variant="standard"
          error={Boolean(errors.password)}
          errmsg={errors.password}
          rules={RULES.password}
        />

        <div className={style["buttonWrapper"]}>
          <ReCAPTCHA
            sitekey="6LcOrj4nAAAAAKpCo99WVG4y_n4i3TK8pwD8f6ma"
            onChange={onChange}
          />
          <CustomButton
            label="Iniciar sesión"
            typeVariant="outlined"
            typeAction="submit"
            disabled={isLoading}
            className={style["button-login"]}
          />
        </div>
      </Stack>

      <div className={style["olvido"]}>
        <NavLink to="/registrar" end>
          Registrar
        </NavLink>
        <NavLink to="/recuperar" end>
          ¿Olvidó su contraseña?
        </NavLink>
      </div>
    </form>
  );
};

export default Login;
