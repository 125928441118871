import React, { useState } from 'react';

import {
  Mencion1,
  Mencion11,
  Mencion2,
  Mencion3,
  Mencion4,
  Mencion12,
  Mencion13,
  Mencion5,
  Mencion6,
  Mencion7,
  Mencion8,
  Mencion9
} from 'components/Forms';

const RenderContent = (
  items: TRenderContent,
  getPostulaciones: any,
  postulaciones: any,
) => {
  switch ((items as any)?.id) {
    case 1:
      return (
        <Mencion1
          getPostulaciones={getPostulaciones}
          postulaciones={postulaciones}
        />
      );

    case 2:
      return (
        <Mencion2
          getPostulaciones={getPostulaciones}
          postulaciones={postulaciones}
        />
      );
    case 3:
      return (
        <Mencion3
          getPostulaciones={getPostulaciones}
          postulaciones={postulaciones}
        />
      );
    case 4:
      return (
        <Mencion4
          getPostulaciones={getPostulaciones}
          postulaciones={postulaciones}
        />
      );
    case 5:
      return (
        <Mencion5
          getPostulaciones={getPostulaciones}
          postulaciones={postulaciones}
        />
      );
    case 6:
      return (
        <Mencion6
          getPostulaciones={getPostulaciones}
          postulaciones={postulaciones}
        />
      );
    case 7:
      return (
        <Mencion7
          getPostulaciones={getPostulaciones}
          postulaciones={postulaciones}
        />
      );
    case 8:
      return (
        <Mencion8
          getPostulaciones={getPostulaciones}
          postulaciones={postulaciones}
        />
      );
    case 9:
      return (
        <Mencion9
          mencionId='9'
          getPostulaciones={getPostulaciones}
          postulaciones={postulaciones}
        />
      );
    case 10:
      return (
        <Mencion9
          mencionId='10'
          getPostulaciones={getPostulaciones}
          postulaciones={postulaciones}
        />
      );
    case 11:
      return (
        <Mencion11
          getPostulaciones={getPostulaciones}
          postulaciones={postulaciones}
        />
      );
    case 12:
      return (
        <Mencion12
          getPostulaciones={getPostulaciones}
          postulaciones={postulaciones}
        />
      );
    case 13:
      return (
        <Mencion13
          getPostulaciones={getPostulaciones}
          postulaciones={postulaciones}
        />
      );
    default:
      return null;
  }
};

type TRenderContent = {
  items: {
    id: number;
    name: string;
  };
};

export default RenderContent;
