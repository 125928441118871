import React, { useState } from 'react';
import FileZone from '../Fields/FileZone';
import { CustomButton } from 'components/CustomButton';
import { Container, Grid, Stack } from '@mui/material';
import { getRegisterMencionServices } from 'services/upload';
import { TextField } from 'components/Fields';
import { useFormHook } from 'hooks/useFormHook';
import { RULES } from 'constants/index';
import { TPremios1 } from 'types/files';
import { useAlert } from 'hooks/useAlert';
import { useSearchParams } from 'react-router-dom';
import Mencion45678 from './Mencion45678';
const errorDefault = {
  postulacion: true,
  cedula: true,
  documentExel: true,
  copiaTrabajo: true
};

const initialDatarow = {
  titulo: '',
  resumen: '',
  fechaPublicacion: '',
  fechaAceptacion: '',
};

const Mencion8 = ({ getPostulaciones, postulaciones }: any) => {
  const premioId = '2';
  const mencionId = '8';
  const tittle = 'investigacion tecnologica';

  return (
    <Mencion45678
      premioId={premioId}
      mencionId={mencionId}
      tittle={tittle}
      postulaciones={postulaciones}
      getPostulaciones={getPostulaciones}
    />
  );
};

export default Mencion8;
