
import { Table } from 'components/Table'
import React from 'react'

const PostulacionLista = ({ columns, pageState, setPageState, premios }: any) => {

  return (
    <>
      <Table
        pageState={pageState}
        setPageState={setPageState}
        columns={columns}
        hideFooter={true}
      />
    </>
  )
}

export default PostulacionLista